<template>
    <main>
        <page-header-compact>
            Configuración de partes Examen Pies
        </page-header-compact>

        <div class="container-fluid mt-n15">
            <div class="card mt-4">
                <div class="card-body">

                    <data-grid :data="pagination"
                           @changePage="changePage($event)"
                           @perPageChange="perPageChange($event)"
                           @search="search($event)">
                            <template #grid>
                                <grid-table>
                                    <template #head>
                                        <grid-cell-header class="bg-light"  v-for="(h,i) in headers" :key="`h_${i}`">{{h}}</grid-cell-header>
                                    </template>
                                    <template #body>
                                        <grid-row v-for="(item, i) in pagination.data" :key="`d_${i}`">
                                            <grid-cell class="text-center small">
                                                {{item.id}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.nombre}}
                                            </grid-cell>
                                            <grid-cell class="text-center small">
                                                {{item.sexo}}
                                            </grid-cell>
                                            <grid-cell class="text-center ">
                                                {{item.activo}}
                                            </grid-cell>
                                         
                                            <grid-cell class="text-center   ">
                                                <button class="btn btn-outline-info btn-xs mx-2" type="button" @click="abrirModal(item.id)"  >
                                                      <i class="fas fa-edit fa-fw" ></i> Editar
                                                </button>
                                            </grid-cell>
                                            <grid-cell class="text-center   ">
                                                <button class="btn btn-outline-success btn-xs mx-2" type="button"  @click="cambiar(item.id)" >
                                                      <i class="fas fa-edit fa-fw" ></i> Cambiar
                                                </button>
                                            </grid-cell>

                                        </grid-row>
                                    </template>
                                </grid-table>
                            </template>
                            <template #action-button>
                                <button  class="btn btn-info btn-sm shadow-sm mb-2" type="button" data-toggle="modal" data-target="#modalPartesExamenPies">
                                    Nuevo &nbsp;<i class="fas fa-plus"></i>
                                </button>
                            </template>
                    </data-grid>
                   <modalPartesExamenPies ref="modalPartesExamenPies" v-on:cargar-partes="getPartesExamenPies"></modalPartesExamenPies>
                    

                </div>
            </div>
           
        </div>

    </main>
</template>
<script>
import PageHeaderCompact from "../../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../../components/common/utilities/DataGrid/GridCellHeader";
import GridRow from "../../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../../components/common/utilities/DataGrid/GridCell";
import Toast from '../../../../components/common/utilities/toast';
import modalPartesExamenPies from "./modalPartesExamenPies";
import partesExamenPiesService from "@/services/partesExamenPiesService";

export default {
    components: {
        PageHeaderCompact,GridCell, GridRow, GridCellHeader, GridTable, DataGrid,modalPartesExamenPies
    },
    data(){
        return {
            partesexamen:{},
            headers : [
                'Id','Nombre','Sexo','Activo','Opción','Cambiar estado'
           ],
           pagination : {
               data : [],
               current_page : 1,
               page : 1,
               per_page : 5,
               search : '',
               total: '',
           }
        }
    },
    methods : {
        async getPartesExamenPies(){
            try {
                this.LoaderSpinnerShow();
                const response = (await partesExamenPiesService.list(this.pagination.search, this.pagination))?.data;
                this.partesexamen = response.data;
                console.log(response.data);
                this.pagination.data = response.data;
                this.pagination.current_page  = response.current_page;
                this.pagination.total = response.total;
                this.LoaderSpinnerHide();

            }catch (e) {
                console.error(e);
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async cambiar(id){
           try {
                this.LoaderSpinnerShow();
                await partesExamenPiesService.cambiarEstado(id);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'success',
                    title: 'Estado cambiado exitosamente'
                });
                this.getPartesExamenPies();
           } catch (e) {
            console.error(e);
            this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
           }
        },
        changePage(page) {
            this.pagination.page = page;
            this.getPartesExamenPies();
        },
        perPageChange(per_page) {
            this.pagination.per_page = per_page;
            this.pagination.page = 1;
            this.getPartesExamenPies();
        },
        search(query){
            this.pagination.search = query;
            this.pagination.page = 1;
            this.getPartesExamenPies();
        },
        abrirModal(id){
           this.$refs.modalPartesExamenPies.edit(id);
        }

    },
    async created(){
       await this.getPartesExamenPies();
    }
}
</script>
