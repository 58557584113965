<template>
    <main>
        

    
        <div >
            <div class="card-body">
                <nav>
                    <div class="nav nav-tabs" id="nav-tab" role="tablist">
                      <button class="nav-link active" id="nav-home-tab" data-toggle="tab" data-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Tipos de historias</button>
                      <button class="nav-link" id="nav-profile-tab" data-toggle="tab" data-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Partes de sistemas</button>
                      <button class="nav-link" id="nav-contact-tab" data-toggle="tab" data-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Partes fisicas</button>
                      <button class="nav-link" id="nav-examenpies-tab" data-toggle="tab"  data-target="#nav-examenpies" type="button" role="tab"  aria-selected="false">Partes examen pies</button>
                    </div>
                  </nav>
                  <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <index-config-historia></index-config-historia>
                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                        <indexParteSistema></indexParteSistema>
                    </div>
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                        <indexParteFisica></indexParteFisica>
                    </div>

                    <div class="tab-pane fade"  id="nav-examenpies" role="tabpanel" aria-labelledby="nav-examenpies-tab" >
                        <indexPartesExamenPies></indexPartesExamenPies>
                    </div>


                  </div>
            </div>
        </div>

    </main>
</template>

<script>

import  indexConfigHistoria from "../../base/BaseConfigTipoHistoria/index";
import  indexParteSistema from "../../base/BaseConfigPartesSistemas/index";
import  indexParteFisica from "../../base/BaseConfigPartesFisicas/index";
import indexPartesExamenPies from "../../base/BaseConfigPartesExamenPies/index"

export default {
    components: {
        indexConfigHistoria,indexParteSistema,indexParteFisica,indexPartesExamenPies
    }
}

</script>